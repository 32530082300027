.wrapper{
    min-height: 100vh;
}

.main{
    min-height: 100vh;
    //overflow: hidden;
    //overflow-y: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }    
    &::-webkit-scrollbar-thumb {
        background-color: #9e9e9e;
    }    
}

.container{
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100vh;
    justify-content: center;
    margin-bottom: 5rem;

    .name{
        font-size: 7rem;
        font-weight: 700;
    }
}

.leo{
    position: relative;
    width: 100%;
    height: 100vh;    
    background: radial-gradient(circle at 0 50%, rgba(0,0,0,0.25) 0%, #fafafa 80%);

    &::after{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        mask-image: url(../../assets/images/dots.png);
        mask-size: 30%;
        mask-repeat: repeat-y;
        background-color: rgba(0,0,0,0.35);
        z-index: -1;
    }

    div{
        position: absolute;
        background-image: url(../../assets/images/leo.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100%;
        transition:all 0.5s ease-out;
        z-index: 9999;
    }
}

@media screen and (max-width: 900px) {
    .main{
        padding: 2rem;
    }

    .container{
        padding: 0;

        .name{
            font-size: 5rem;
        }
    }
    
    .leo{
        position:absolute;
        top: 0;
        left: 0;
        background: radial-gradient(circle at 50% 100%, rgba(0,0,0,0.25) 0%, #fafafa 80%);
        z-index: -1;

        &::after{
            display: none;
        }

        div{
            background-size: 100px;
            background-position: bottom;
            position: absolute;
            bottom:0;
            width: 100%;
            height: 100%;
        }
    }
    
}