.wrapper{
    background-image: linear-gradient(to right, transparent 0%, transparent 35%, rgba(250,250,250,.95) 60%, rgba(250,250,250,.99) 100%);
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, transparent 50%, rgba(0,0,0,0.25)) 1 100%;
    border-image-slice: 1;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    height: 100px;
    top: 0;
    z-index: 99999;

    &::before{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        mask-image: url(../../assets/images/dots.png);
        mask-size: 20%;
        mask-repeat: repeat-y;
        mask-position: left;
        background-color: rgba(0,0,0,0.25);
        transform: rotate(180deg);


    }

    .toolbar{
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}

.top{
    position: absolute;
    right: 1.75rem;
    top: 1rem;

    &:hover{
        text-decoration: none;
    }
}

@media (max-width: 900px) {
    .wrapper{
        height: 150px;
        top: 0;
        background-image: none;
        border-image: none;
        background-color: rgba(250,250,250,.95);
        
        &::before{
            mask-size: contain;
            mask-position: left;
            mask-repeat: no-repeat;
        }

        &::after{
            content:'';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../assets/images/leo.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left;
        }

        .toolbar{
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 35%;
            width: 65%;
            z-index: 99999;
            overflow: hidden;
            padding-right: 0;

            h4{
                font-weight: 400;
                margin-left:0;

                span{
                    font-weight: 600;
                    font-size: 1.2rem;
                }
            }
        }
    }
}