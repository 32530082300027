.leo{
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;    
    background: radial-gradient(circle at 0 50%, rgba(0,0,0,0.35) 0%, #fafafa 70%);

    &::after{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        mask-image: url(../../../assets/images/dots.png);
        mask-size: 60%;
        mask-repeat: repeat-y;
        background-color: rgba(0,0,0,0.5);
        z-index: -1;
    }

    div:first-child{
        position: absolute;
        background-image: url(../../../assets/images/leo-body.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100%;
        transition:transform 0.5s ease-out;
        z-index: 9999;
    }

    div:nth-child(2){
        position: absolute;
        background-image: url(../../../assets/images/leo-head.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100%;
        transition: transform 0.5s ease-out;
        z-index: 9999;

        &.click{
            background-image: url(../../../assets/images/leo-head-click.png);
        }
    }

    div:last-child{
        position: absolute;
        background-image: url(../../../assets/images/leo-glasses.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100%;
        transition:transform 0.5s ease-out;
        z-index: 9999;
    }
}

@media screen and (max-width: 900px) {
    .leo{
        display: none;
    }   
}