@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&family=Rubik:wght@300;400;500;600;700&display=swap');


html,
body{
    font-family: 'Lora', sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }    
    &::-webkit-scrollbar-thumb {
        background-color: #9e9e9e;
    }
}

#root{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }    
    &::-webkit-scrollbar-thumb {
        background-color: #9e9e9e;
    }
}

.bold{
    font-weight: 500;
}

a{
    text-decoration: none;
    color: inherit;
    font-weight: 500;

    &:hover{
        text-decoration: underline;
    }
}

.with-fade{
    h1{
        transition: all 0.35s linear;
        transform-origin: bottom left;
        transform: scale(0.90);
    }

    &.fade-in{
        h1{
            transform: scale(1);
        }
    }
}
