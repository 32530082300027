@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Montserrat:wght@900&display=swap');

.back{
    margin: auto 0;
    text-decoration: inherit;
    z-index: 1205;

    &:hover{
        text-decoration: inherit
    }

    i{
        font-size: 1.5rem;
    }
}

.header,
.content{
    width: 100%;
    margin:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }    
    &::-webkit-scrollbar-thumb {
        background-color: #9e9e9e;
    } 
}

.content{
    height: 100%;
}

.header{
    text-justify: inter-character;
    text-align: justify;
    padding: 0 2rem;
    min-height: 100%;

    ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        list-style: none;
        font-family: 'Rubik', sans-serif;
        font-size: 0.85rem;
        padding: 0;
        margin: 0;

        li{
            background-color: #000;
            color: #eee;
            padding: 0.5rem;
            margin: 0 0.25rem 0.25rem 0;
            opacity: 0;
            animation-name: backInUp;
            animation-duration: 1s; 
            animation-fill-mode: forwards;
        }

        @for $i from 1 through 20 {
            li:nth-child(#{$i}){
                animation-delay: 0.1s * $i;
            }
        }
    }


    p {
        line-height: 1.5rem;
    }

    h3 {
        margin-top:0.2rem;

    }
}

.logo{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    //background-color: #eee;
    height: 100px;
    width: 300px;
    margin: 1rem auto;
    transform: translateX(-24px);

    &.xitrus{
        background-image: url(../../assets/images/xitrus.png);
        height: 56px;
        width: 221px;
    }

    &.siteboss{
        background-image: url(../../assets/images/siteboss.svg);
        height: 60px;
        width: auto;
    }

    &.chainhounds{
        background-image: url(../../assets/images/chainhounds.png);
        height: 45px;
        width: auto;
    }

    &.showzlive{
        background-image: url(../../assets/images/showzlive.png);
        height: 50px;
        width: auto;
    }    

    &.wizzee{
        position: relative;
        height: 60px;
        &::after{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            content: 'wizzee';
            font-family: 'Inter', sans-serif;
            font-weight: 900;
            font-size:2.5rem;
        }
    }

    &.thebifröst{
        position: relative;
        height: 55px;
        &::after{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            content: 'The Bifröst';
            text-transform: uppercase;
            white-space: nowrap;
            font-family: 'Montserrat', sans-serif;
            font-weight: 900;
            font-size:2.25rem;
        }
    }

    &.soundsofeth{
        position: relative;
        height: 54px;
        
        &::before{
            position: absolute;
            top: 50%;
            left: 50%;
            height: 35px;
            width: 53px;
            line-height: 1rem;
            text-align: right;
            transform: translate(calc(-50% - 30px), -50%);
            content: 'sounds -of';
            font-family: 'Rubik', sans-serif;
            font-weight: 400;
            font-size:1rem;
        }
        &::after{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(calc(-50% + 40px), -50%);
            content: 'ETH';
            white-space: nowrap;
            font-family: 'Rubik', sans-serif;
            font-weight: 900;
            font-size:2.5rem;
        }
    }

}

@media screen and (max-width: 900px) {
    .main{
        padding: 2rem;
    }

    .container{
        padding: 0;
    }

    .header,
    .content {
        max-height: initial;
        height: 100%;
        text-align: left;
    }

    .stack-container{
        padding-top: 0 !important;
    }
}

@keyframes bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    from {
      opacity: 0;
      transform: translate3d(0, 3000px, 0) scaleY(5);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(0, -20px, 0) scaleY(0.9);
    }
  
    75% {
      transform: translate3d(0, 10px, 0) scaleY(0.95);
    }
  
    90% {
      transform: translate3d(0, -5px, 0) scaleY(0.985);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes backInUp {
    0% {
      transform: translateY(1200px) scale(0.7);
      opacity: 0.7;
    }
  
    80% {
      transform: translateY(0px) scale(0.7);
      opacity: 0.7;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }