.carousel {
    position: relative;
    width: 100%;
    height: fit-content;
    max-height: 100vh;
    min-height: 400px;
    display: block;
    overflow: hidden;
}
  
.image-container {
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding:1rem;
    
    img {
        width: 70%;
        height: auto;
        object-fit: contain;
        z-index:-1;
        opacity: 0;
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) scale(0.75);
        transition: all 0.25s ease-in-out;
        border:1px solid #000;
    
        &.active{
            position:absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) scale(1);
            z-index: 1200;
            opacity: 1;
        }

        &.prev,
        &.next{
            position:absolute;
            opacity: 0.45;
            transform: scale(0.75);
            z-index: 1197;
        }
        &.prev{
            left:0;
            transform: translate(0, -50%) scale(0.75);
        }    
        &.next{
            right:0;
            transform: translate(-30%, -50%) scale(0.75);
        }
    }

}
  
.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1205;
}
  
.prev-button {
    left: 0;
}
  
.next-button {
    right: 0;
}
  
.dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1207;
    transition: all 0.25s ease-in-out;
  
    span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.5);
        border:1px solid rgba(0,0,0,0.5);
        margin: 0 5px;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &.active {
            background-color: rgba(0,0,0,0.75);
        }
    }
}

@media screen and (max-width: 900px) {
    .carousel {
        min-height: 300px;
        padding: 0;
        margin-bottom: 2rem;
    }
    
    .prev-button,
    .next-button {
        display: none;
    }
    
}